import styled from "styled-components";
import { Text } from 'grommet'
import {MakerFutureProvisions, MarketPortfolio, ProvisionInfo} from "../../types";
import {BigNumber} from "ethers";
import {SelectMarket} from "../../components/market-select";
import {MarketWithdrawableMargin} from "../../hooks/useWithdrawableMargin";
import {ViewType} from "../../app.types";

export interface PortfolioProps {
    market?: SelectMarket
    isMarketSelected: boolean
    portfolio: MarketPortfolio[]
    marketPortfolio?: MarketPortfolio
    marketsWithdrawableMargin: MarketWithdrawableMargin[]
    isDropdownOpened: boolean
    setDropdownOpened: (isOpened: boolean) => void
    viewType: ViewType
}

export const FilterTitle = styled(Text)<{ isActive: boolean }>`
    font-weight: 500;
    font-size: 18px;
    color: ${props => props.isActive
            ? props.theme.global.colors.textHeader
            : props.theme.global.colors.textSecondary
    };
    cursor: pointer;
    transition: color 150ms;

    &:hover {
        color: ${props => props.theme.global.colors.textHeader}
    }
`

export interface LiquidityProvisionSummary {
    futureId: string
    provisionInfo: Omit<ProvisionInfo, 'payerDv01' | 'receiverDv01'>
}

export const getMakerProvisionsSummary = (makerProvisions: MakerFutureProvisions[]): LiquidityProvisionSummary[] => {
    const filteredMakerPositions = makerProvisions.filter(
      pos => pos.provisions
        .filter(provision => !provision.notional.total.isZero())
        .length > 0)

    const provisionsSummary: Array<{
        futureId: string,
        provisionInfo: Omit<ProvisionInfo, 'payerDv01' | 'receiverDv01'>
    }> = []

    for(let i = 0; i < filteredMakerPositions.length; i++) {
        const makerProvision = filteredMakerPositions[i]
        const { futureId } = makerProvision
        if(provisionsSummary.find(item => item.futureId === futureId)) {
            continue
        }

        const allProvisions = filteredMakerPositions
          .filter(item => item.futureId === futureId)
          .map(item => item.provisions)
          .flat()

        let lower, upper, payer, receiver, total
        lower = upper = payer = receiver = total = BigNumber.from(0)

        for(let j = 0; j < allProvisions.length; j++) {
            const item = allProvisions[j]
            if(j === 0) {
                lower = item.bounds.lower
                upper = item.bounds.upper
                total = item.notional.total
                payer = item.notional.payer
                receiver = item.notional.receiver
            } else {
                if(item.bounds.lower.lt(lower)) {
                    lower = item.bounds.lower
                }
                if(item.bounds.upper.gt(upper)) {
                    upper = item.bounds.upper
                }
                total = total.add(item.notional.total)
                payer = payer.add(item.notional.payer)
                receiver = receiver.add(item.notional.receiver)
            }
        }

        const provisionInfo: Omit<ProvisionInfo, 'payerDv01' | 'receiverDv01'> = {
            bounds: {
                lower,
                upper,
            },
            notional: {
                total,
                payer,
                receiver
            },
            requiredMargin: makerProvision.requiredMargin
        }

        const summary = {
            futureId,
            provisionInfo
        }
        provisionsSummary.push(summary)
    }
    return provisionsSummary
}
