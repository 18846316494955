import React, {useRef, useState} from 'react'
import {Anchor, Box, Button, Text} from 'grommet'
import {UserOutlined} from '@ant-design/icons'
import { connect, disconnect } from 'wagmi/actions'
import styled from "styled-components";
import {useTranslation} from "react-i18next";
import {useConnect, useDisconnect, useAccount, useNetwork} from "wagmi";
import {ReactComponent as MetamaskLogo} from '../../assets/images/metamask_logo.svg';
import {ReactComponent as WalletConnectLogo} from '../../assets/images/wallet_connect_logo.svg';
import {ReactComponent as WalletImg} from '../../assets/images/wallet.svg';
import {ReactComponent as CopyImg} from '../../assets/images/copy.svg';
import {ReactComponent as LinkImg} from '../../assets/images/link.svg';
import {getExplorerUrl, truncateEthAddress} from "../../utils";
import {Arrow} from "../Icon";
import {WidgetContainer} from "../widgets";
import useOnClickOutside from "../../hooks/useOnClickOutside";
import {Dropdown, MenuProps, Modal} from "antd";
import {ChainName} from "../../config";
import {ViewType} from "../../app.types";
import {MenuIconContainer} from "./common";
import {useActiveModal} from "../../providers/ModalsProvider";
import {PageModal} from "../../constants";

const ConnectedButtonContainer = styled(Button)`
  background: none;
  border-radius: 3px;
  font-family: 'Roboto',serif;
  padding: 5px 10px;
`

const ConnectedButton = (props: {
  viewType?: ViewType
}) => {
  const { address, connector } = useAccount()
  const { t } = useTranslation()
  const { chain } = useNetwork()
  const [isCopied, setIsCopied] = useState(false)
  const [timeoutId, setTimeoutId] = useState<NodeJS.Timeout>()
  const [isOpened, setOpened] = useState(false)

  const ref = useRef(null)
  useOnClickOutside(ref, () => setOpened(false));

  const onCopyAddress = async () => {
    navigator.clipboard.writeText(address || '')
    setIsCopied(true)
    clearTimeout(timeoutId)
    const id = setTimeout(() => {
      setIsCopied(false)
    }, 2000)
    setTimeoutId(id)
  }

  const onDisconnectClicked = () => {
    disconnect()
    setOpened(false)
  }

  const viewOnExplorer = () => window.open(`${getExplorerUrl()}/address/${address}`, '_blank')

  const dropdownContent = <WidgetContainer width={'220px'}>
    <Box direction={'row'} gap={'12px'} align={'center'}>
      {connector?.name === 'MetaMask' &&
          <Box><MetamaskLogo width={'20px'} height={'20px'} /></Box>
      }
      {connector?.name === 'WalletConnectLegacy' &&
          <Box><WalletConnectLogo width={'20px'} height={'20px'} /></Box>
      }
      <Box>
        <Text weight={500}>
          {truncateEthAddress(address || '')}
        </Text>
      </Box>
    </Box>
    <Box width={'128px'} margin={{ top: '8px' }}>
      <Button
        style={{ padding: '4px 0px' }}
        onClick={onDisconnectClicked}>
        {t('account.disconnect')}
      </Button>
    </Box>
    <Box margin={{ top: '16px' }} pad={{ top: '8px' }} border={{ side: 'top' }}>
      <Text color={'textSecondary'}>{t('account.network')}</Text>
      {chain &&
          <Text weight={500}>{ChainName[chain.id]}</Text>
      }
    </Box>
    <Box margin={{ top: '16px' }} pad={{ top: '8px' }} border={{ side: 'top' }}>
      <Box direction={'row'} pad={{ top: '8px' }} gap={'16px'} align={'center'} onClick={onCopyAddress}>
        <CopyImg width={'22px'} height={'22px'} style={{ color: '#999AAA' }} />
        {!isCopied &&
            <Text>{t('account.copy_address')}</Text>
        }
        {isCopied &&
            <Text>{t('account.copied')}</Text>
        }
      </Box>
      <Box direction={'row'} gap={'16px'} align={'center'} margin={{ top: '8px' }} onClick={viewOnExplorer}>
        <LinkImg width={'22px'} height={'22px'} style={{ color: '#999AAA' }} />
        <Text>{t('account.view_on_explorer')}</Text>
      </Box>
    </Box>
  </WidgetContainer>

  const items: MenuProps['items'] = [{
    key: 'account',
    label: dropdownContent
  }]

  if(props.viewType === 'mobile') {
    return <Dropdown
      menu={{ items }}
      open={isOpened}
      trigger={['click']}
      overlayClassName={'antd_dropdown_overlay'}
    >
      <MenuIconContainer onClick={(e) => {
        setOpened(!isOpened)
      }}>
        <UserOutlined style={{ color: '#E4E4E8', fontSize: '18px' }} />
      </MenuIconContainer>
    </Dropdown>
  }

  return <Dropdown
    menu={{ items }}
    open={isOpened}
    trigger={['click']}
    overlayClassName={'antd_dropdown_overlay'}
  >
    <ConnectedButtonContainer
      onClick={(e) => {
        setOpened(!isOpened)
        // const button = document.querySelectorAll('.connected_button')
        // if(button) {
        //   if(button[0].contains(e.currentTarget)) {
        //   }
        // }
      }}
    >
      <Box direction={'row'} gap={'8px'} align={'center'}>
        {connector?.name === 'MetaMask' &&
            <Box>
                <MetamaskLogo width={'16px'} height={'16px'} />
            </Box>
        }
        {connector?.name === 'WalletConnect' &&
            <Box>
                <WalletConnectLogo width={'16px'} height={'16px'} />
            </Box>
        }
        <Box>
          {(address) &&
              <Text color={'text'} weight={500}>
                {truncateEthAddress(address || '')}
              </Text>
          }
        </Box>
        <Box>
          <Arrow direction={isOpened ? 'up' : 'down'} width={'10px'} />
        </Box>
      </Box>
    </ConnectedButtonContainer>
  </Dropdown>
}

const ConnectButton = styled(Button)`
    text-shadow: 0 0 10px rgba(0,0,0,0.2);
    transition: background-color 1s;
    background: linear-gradient(150deg, #53A986 10%, transparent), #97C255;

    &:hover, &:focus {
        background-color: #53A986;
        color: #fff;
    }
`

const NotConnectedButton = (props: { viewType?: ViewType }) => {
  const { t } = useTranslation()
  const { setActiveModal } = useActiveModal()

  if(props.viewType === 'mobile') {
    return <MenuIconContainer onClick={() => setActiveModal(PageModal.connectWallet)}>
      <WalletImg width={'20px'} height={'20px'} />
    </MenuIconContainer>
  }

  return <ConnectButton onClick={() => setActiveModal(PageModal.connectWallet)}>
    <Text weight={500} color={'white'}>{t('wallet.connect_wallet')}</Text>
  </ConnectButton>
}

export const ConnectWallet = (props: {
  viewType?: ViewType
}) => {
  const { isConnected } = useAccount()

  return <Box>
    {isConnected && <ConnectedButton viewType={props.viewType} />}
    {!isConnected && <NotConnectedButton viewType={props.viewType} />}
  </Box>
}
