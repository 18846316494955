import {ethers} from "ethers";
import axios, {AxiosError} from "axios";
import {isMainnet} from "../config";

const baseUrl = 'https://apiplus.squidrouter.com/v2'
const integratorId = "rho-protocol-b0e34341-856a-4a79-8053-ee66ed44ee77"

export const getSquidChains = async () => {
  const { data } = await axios.get(`${baseUrl}/chains`, {
    headers: {
      'x-integrator-id': integratorId,
    },
  });
  return data;
};

export interface SquidToken {
  address: string
  chainId: string
  coingeckoId: string
  decimals: number
  logoURI: string
  name: string
  symbol: string
  type: string
  usdPrice: number
}

export const getSquidTokens = async (): Promise<SquidToken[]> => {
  const { data } = await axios.get<{ tokens: SquidToken[] }>(`${baseUrl}/tokens`, {
    headers: {
      'x-integrator-id': integratorId,
    },
  });
  return data.tokens;
};

export interface SquidRouteParams {
  enableBoost: boolean
  fromAddress: string
  fromAmount: string
  fromChain: string
  fromToken: string
  postHook: any[]
  prefer: any[]
  slippage: number
  toAddress: string
  toChain: string
  toToken: string
}

export interface SquidRouteTransactionRequest {
  data: string
  gasLimit: string
  gasPrice: string
  maxFeePerGas: string
  maxPriorityFeePerGas: string
  routeType: 'EVM_ONLY'
  target: string
  value: string
}

export interface SquidRouteEstimate {
  actions: any[]
  aggregatePriceImpact: string
  aggregateSlippage: number
  estimatedRouteDuration: number
  exchangeRate: string
  feeCosts: any[]
  fromAmount: string
  fromAmountUSD: string
  fromToken: SquidToken
  gasCosts: any[]
  isBoostSupported: boolean
  sendAmount: string
  toAmount: string
  toAmountMin: string
  toAmountMinUSD: string
  toAmountUSD: string
  toToken: SquidToken
}

export interface SquidRoute {
  params: SquidRouteParams
  estimate: SquidRouteEstimate
  transactionRequest: SquidRouteTransactionRequest
}

export interface SquidRouterQuote {
  route: SquidRoute
  requestId: string
}

export const getSquidRouteQuote = async (params: any, signal?: AbortSignal): Promise<SquidRouterQuote> => {
  try {
    const result = await axios.post<{ route: SquidRoute }>(
      `${baseUrl}/route`,
      params,
      {
        signal,
        headers: {
          "x-integrator-id": integratorId,
          "Content-Type": "application/json",
        },
      }
    );
    const requestId = result.headers["x-request-id"] as string;
    return {
      route: result.data.route,
      requestId: requestId
    };
  } catch (error) {
    if ((error as AxiosError).response) {
      console.error("API error:", (error as AxiosError).response?.data);
    }
    console.error("Error with parameters:", params);
    throw error;
  }
};

export enum SquidStatusType {
  SRC_GATEWAY_CALLED = "source_gateway_called",
  DEST_GATEWAY_APPROVED = "destination_gateway_approved",
  DEST_EXECUTED = "destination_executed",
  EXPRESS_EXECUTED = "express_executed",
  DEST_ERROR = "error",
  ERROR_FETCHING_STATUS = "error_fetching_status",
  SUCCESS = "success",
}

export const SquidStatusTypeAlias = {
  [SquidStatusType.SRC_GATEWAY_CALLED]: 'Waiting for confirmation',
  [SquidStatusType.DEST_GATEWAY_APPROVED]: 'Waiting for confirmation',
  [SquidStatusType.DEST_EXECUTED]: 'Waiting for confirmation',
  [SquidStatusType.EXPRESS_EXECUTED]: 'Success',
  [SquidStatusType.DEST_ERROR]: 'Error',
  [SquidStatusType.ERROR_FETCHING_STATUS]: 'Error',
  [SquidStatusType.SUCCESS]: 'Success',
}

export interface SquidStatus {
  id: string
  status: SquidStatusType
  gasStatus: string
  isGMPTransaction: string
  axelarTransactionUrl: string
  fromChain: any
  toChain: any
}

export interface GetStatusParams {
  transactionId: string
  requestId: string
  fromChainId: number
  toChainId: number
}

export const getSquidStatus = async (params: GetStatusParams) => {
  const result = await axios.get<SquidStatus>(`${baseUrl}/status`,  {
    params,
    headers: {
      'x-integrator-id': integratorId,
    }
  });
  return result.data;
};
