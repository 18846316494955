import React, {useEffect, useState} from 'react'
import {Modal} from "antd";
import {Box} from "grommet";
import {useLocation, useParams, useSearchParams} from "react-router-dom";
import {PageModal} from "../../constants";
import {MarketInfo} from "../../types";
import {MarginManagement, OperationType} from "../../pages/margin-management";
import {useProtocolData} from "../../providers/ProtocolDataProvider";
import {MarginTransfer} from "../../pages/margin-transfer";
import {RestrictedAccessModal} from "../restricted-access-modal";
import {getFutureByAlias} from "../../utils";
import {InvestModal} from "../../pages/earn/InvestModal";
import {WithdrawModal} from "../../pages/earn/WithdrawModal";
import {ConnectWalletModal} from "../../modals/ConnectWallet";
import {useActiveModal} from "../../providers/ModalsProvider";

const defaultWidth = 620
const ModalsWidth: Record<PageModal, number> = {
  [PageModal.margin]: defaultWidth,
  [PageModal.restrictedAccess]: defaultWidth,
  [PageModal.earnInvest]: defaultWidth,
  [PageModal.earnRedeem]: defaultWidth,
  [PageModal.transfer]: defaultWidth,
  [PageModal.connectWallet]: defaultWidth,
}

export const ModalsHOC = (props: { children: React.ReactNode }) => {
  const [searchParams, setSearchParams] = useSearchParams()
  const routeParams = useParams()
  const location = useLocation();

  const routeFutureAlias = routeParams.futureAlias || ''
  const isRestrictedLocation = location.pathname === '/restricted'

  const { markets } = useProtocolData()
  const [market, setMarket] = useState<MarketInfo>()

  const { activeModal, setActiveModal, closeModals, modalParams } = useActiveModal()

  useEffect(() => {
    if(isRestrictedLocation) {
      setActiveModal(PageModal.restrictedAccess)
    } else {
     // if(activeModal !== PageModal.restrictedAccess) {
     //   setActiveModal(PageModal.restrictedAccess)
     // }
    }
  }, [activeModal, isRestrictedLocation]);

  // TODO: refactor using useFutureAlias hook
  useEffect(() => {
    if(markets.length > 0) {
      const data = getFutureByAlias(routeFutureAlias, markets)
      if(data) {
        setMarket(data.market)
      }
    }
  }, [routeFutureAlias, markets]);

  const handleCancel = () => {
    if(activeModal !== PageModal.restrictedAccess) {
      closeModals()
    }
  }

  const modalStyles = {
    mask: {
      backdropFilter: 'blur(4px)',
    },
  }

  return <Box>
    {props.children}
    <Modal
      className={'antd_modal_container'}
      title={null}
      footer={null}
      closable={false}
      width={activeModal && ModalsWidth[activeModal] ? ModalsWidth[activeModal] : defaultWidth}
      open={!!activeModal}
      styles={modalStyles}
      onCancel={handleCancel}
    >
      {activeModal === PageModal.restrictedAccess &&
          <RestrictedAccessModal />
      }
      {activeModal === PageModal.margin &&
          <MarginManagement
              marketId={modalParams?.marketId || market?.descriptor.id}
              operationType={modalParams?.type as OperationType}
              onClose={handleCancel}
          />
      }
      {activeModal === PageModal.transfer &&
          <MarginTransfer
              marketId={modalParams?.marketId || market?.descriptor.id}
              onClose={handleCancel}
          />
      }
      {activeModal === PageModal.earnInvest &&
          <InvestModal
              onClose={handleCancel}
          />
      }
      {activeModal === PageModal.earnRedeem &&
          <WithdrawModal
              onClose={handleCancel}
          />
      }
      {activeModal === PageModal.connectWallet &&
        <ConnectWalletModal />
      }
    </Modal>
  </Box>
}
