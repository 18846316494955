import {readContract} from "wagmi/actions";
import Erc20Abi from "../abi/erc20MockABI.json";
import {enrichResponse} from "./utils";
import config from '../config'
import {BigNumber} from "ethers";

const { routerContractAddress } = config

export const getAllowance = async (
  contractAddress: string,
  ownerAddress: string,
  spenderAddress = routerContractAddress,
  chainId = config.chainId
): Promise<BigNumber> => {
  const data = await readContract({
    address: contractAddress as `0x${string}`,
    abi: Erc20Abi,
    functionName: 'allowance',
    args: [ownerAddress, spenderAddress],
    chainId
  })
  return enrichResponse(data)
}

export const getBalanceOf = async (
  contractAddress: string,
  userAddress: string,
  chainId = config.chainId
): Promise<BigNumber> => {
  console.log('Get balanceOf1', contractAddress)
  const data = await readContract({
    address: contractAddress as `0x${string}`,
    abi: Erc20Abi,
    functionName: 'balanceOf',
    args: [userAddress],
    chainId,
  })
  return enrichResponse(data)
}

export const approve = async (
  contractAddress: string,
  collateral: BigNumber,
  chainId = config.chainId
): Promise<BigNumber> => {
  const data = await readContract({
    address: contractAddress as `0x${string}`,
    abi: Erc20Abi,
    functionName: 'approve',
    args: [routerContractAddress, collateral],
    chainId
  })
  return enrichResponse(data)
}
