import React, {useMemo, useState} from 'react'
import {WidgetContainer} from "../../../components";
import {Box, Text} from "grommet";
import {FilterTitle, getMakerProvisionsSummary, PortfolioProps} from "../common";
import {OpenPositions} from "./open-positions";
import {useProtocolData} from "../../../providers/ProtocolDataProvider";
import {LiquidityProvisions} from "./liquidity-provisions";
import {useUserPositions} from "../../../hooks/useUserPositions";
import {useAccount} from "wagmi";
import {ClosedPositions} from "./closed-positions";
import {UserPositions} from "../../../api/dataService";
import {FutureInfo, MarketInfo} from "../../../types";

type BottomMenuOption = 'openPositions' | 'closedPositions' | 'liquidityProvisions'

const sortByMaturity = (a: UserPositions, b: UserPositions) => {
  const diffOpenPosition = a.openPosition.maturity - b.openPosition.maturity
  const diffClosedPosition = a.closedPosition.maturity - b.closedPosition.maturity
  return diffOpenPosition !== 0 ? diffOpenPosition : diffClosedPosition
}

const sortByProtocolOrder = (
  a: UserPositions,
  b: UserPositions,
  markets: MarketInfo[],
  futures: FutureInfo[]
) => {
  const aIndex = futures.findIndex((item) => item.id === a.futureId)
  const bIndex = futures.findIndex((item) => item.id === b.futureId)

  if(aIndex !== -1 || bIndex !== -1) {
    return aIndex - bIndex
  }

  // Matured future - sort by market index and maturity
  const aMarketIndex = markets.findIndex((item) => item.descriptor.id === a.marketId)
  const bMarketIndex = markets.findIndex((item) => item.descriptor.id === b.marketId)

  if(aMarketIndex !== bMarketIndex) {
    return aMarketIndex - bMarketIndex
  }

  const diffOpenPosition = a.openPosition.maturity - b.openPosition.maturity
  const diffClosedPosition = a.closedPosition.maturity - b.closedPosition.maturity

  return diffOpenPosition !== 0 ? diffOpenPosition : diffClosedPosition
}

export const BottomPanel = (props: PortfolioProps) => {
  const { viewType, market: selectedMarket } = props

  const { address: userAddress } = useAccount()
  const [bottomMenuOption, setBottomMenuOption] = useState<BottomMenuOption>('openPositions')

  const { markets, portfolio } = useProtocolData()
  const { userPositions } = useUserPositions(userAddress)

  const isMarketSelected = selectedMarket && selectedMarket !== 'all'
  const futures = useMemo(() => markets.flatMap(item => item.futures), [markets])

  const openUserPositions = userPositions
    ? userPositions
      .sort((a, b) => sortByProtocolOrder(a, b, markets, futures))
      .filter(item => {
        const { openPosition } = item
        const isOpenPosition = openPosition && openPosition.notional !== '0'
        const isExistedPosition = item.openPosition.notional && futures.find(future => future.id === item.futureId)
        if(selectedMarket !== 'all') {
          return isOpenPosition && isExistedPosition && selectedMarket?.futures.find(future => item.futureId === future.id)
        }
        return isOpenPosition && isExistedPosition
      })
      .map(item => item.openPosition)
    : []

  const closedUserPositions = userPositions
    ? userPositions
      .sort((a, b) => sortByProtocolOrder(a, b, markets, futures))
      .filter(item => {
        const marketActive = markets.find(market => market.descriptor.id === item.marketId)
        const hasClosedPosition = item.closedPosition.profitAndLoss !== 0
        if (selectedMarket !== 'all') {
          return marketActive && hasClosedPosition && selectedMarket?.descriptor.id === item.marketId
        }
        return marketActive && hasClosedPosition
      })
      .map(item => item.closedPosition)
    : []

  const liquidityProvisions = useMemo(() => {
    return portfolio
      .flatMap(portfolioItem => getMakerProvisionsSummary(portfolioItem.futureMakerProvisions))
      .filter((position) => {
        const future = futures.find(item => item.id === position.futureId)
        const isActive = future && future.termStart.add(future.termLength).toNumber() * 1000 > Date.now()
        const isBelongsToMarket = isMarketSelected && future && future.marketId === selectedMarket.descriptor.id
        return isActive && (selectedMarket === 'all' || isBelongsToMarket)
      })
      .sort((positionA, positionB) => {
        const a = futures.find(item => item.id === positionA.futureId)
        const b = futures.find(item => item.id === positionB.futureId)
        if(a && b) {
          return a.termStart.add(a.termLength).gt(b.termStart.add(b.termLength)) ? 1 : -1
        }
        return 1
      })
  }, [portfolio, selectedMarket])

  const selectedItems = bottomMenuOption === 'openPositions'
    ? openUserPositions : bottomMenuOption === 'closedPositions'
      ? closedUserPositions
      : liquidityProvisions

  const filterCommonStyle = {
    fontSize: viewType === 'mobile' ? '14px' : '18px',
    fontWeight: viewType === 'mobile' ? 400 : 500,
  }

  return <WidgetContainer style={{ height: '100%' }}>
    <Box>
      <Box direction={'row'} gap={'16px'}>
        <FilterTitle
          style={filterCommonStyle}
          isActive={bottomMenuOption === 'openPositions'}
          onClick={() => setBottomMenuOption('openPositions')}
        >
          Open positions ({openUserPositions.length})
        </FilterTitle>
        <FilterTitle
          isActive={bottomMenuOption === 'closedPositions'}
          style={filterCommonStyle}
          onClick={() => setBottomMenuOption('closedPositions')}
        >
          Closed positions ({closedUserPositions.length})
        </FilterTitle>
        <FilterTitle
          isActive={bottomMenuOption === 'liquidityProvisions'}
          style={filterCommonStyle}
          onClick={() => setBottomMenuOption('liquidityProvisions')}
        >
          {viewType === 'mobile' ? 'LPs' : 'Liquidity provisions'} ({liquidityProvisions.length})
        </FilterTitle>
      </Box>
    </Box>
    <Box margin={{ top: '16px' }}>
      {bottomMenuOption === 'openPositions' &&
          <OpenPositions
              viewType={viewType}
              market={selectedMarket}
              positions={openUserPositions}
              isActivePositions={bottomMenuOption === 'openPositions'}
          />
      }
      {bottomMenuOption === 'closedPositions' &&
          <ClosedPositions
              viewType={viewType}
              market={selectedMarket}
              positions={closedUserPositions}
              isActivePositions={bottomMenuOption === 'closedPositions'}
          />
      }
      {['liquidityProvisions'].includes(bottomMenuOption) &&
        <LiquidityProvisions
            viewType={viewType}
            market={selectedMarket}
            items={liquidityProvisions}
        />
      }
    </Box>
    {selectedItems.length === 0 &&
        <Box width={'100%'} align={'center'} justify={'center'} margin={{ top: '32px' }}>
            <Text size={'22px'}>No {
              bottomMenuOption === 'openPositions' ? 'open positions'
                : bottomMenuOption === 'closedPositions' ? 'closed positions'
                  : 'liquidity provisions'
            } found</Text>
        </Box>
    }
  </WidgetContainer>
}
