import {PortfolioProps} from "../common";
import {Box} from "grommet";
import {ChartContainerHeight, LeftColumnWidth} from "../constants";
import {MarketSelect} from "../../../components/market-select";
import {MarketInfo} from "../../../types";
import {getMarketAlias} from "../../../utils";
import {WidgetsBox} from "../../../components";
import {BadgesPanel} from "../badges-panel";
import {PortfolioHistoryChart} from "../history-chart";
import {BottomPanel} from "../bottom-panel";
import React from "react";
import {useProtocolData} from "../../../providers/ProtocolDataProvider";
import {useFutureAlias} from "../../../hooks/useFutureAlias";
import {useNavigate} from "react-router-dom";

const gap = '16px'

export const PortfolioMobile = (props: PortfolioProps) => {
  const {
    market: selectedMarket,
    isDropdownOpened,
    setDropdownOpened
  } = props

  const navigate = useNavigate();
  const { markets } = useProtocolData()
  const futureData = useFutureAlias(markets)

  return <Box margin={{ bottom: '16px' }} align={'center'}>
    <Box
      margin={{ top: gap }}
      width={'calc(100vw - 16px)'}
    >
      <Box>
        <MarketSelect
          viewType={props.viewType}
          mode={'markets'}
          market={selectedMarket}
          future={futureData?.future}
          onDropdownChange={setDropdownOpened}
          onSelect={(market: MarketInfo | 'all') => {
            navigate(`/portfolio/${market === 'all' ? 'all' : getMarketAlias(market)}`)
          }}
        />
      </Box>
      <Box margin={{ top: gap }}>
        <WidgetsBox overlay={isDropdownOpened}>
          <BadgesPanel {...props} />
        </WidgetsBox>
      </Box>
      <Box margin={{ top: gap }}>
        <WidgetsBox
          height={ChartContainerHeight}
          overlay={isDropdownOpened}
        >
          <PortfolioHistoryChart {...props} />
        </WidgetsBox>
      </Box>
    </Box>
    <WidgetsBox
      margin={{ top: gap }}
      overlay={isDropdownOpened}
      style={{ minHeight: `calc(100vh - ${ChartContainerHeight} - 110px)` }}
    >
      <BottomPanel {...props} />
    </WidgetsBox>
  </Box>
}
