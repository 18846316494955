import React from 'react'
import {Anchor, Box, Table, TableBody, TableCell, TableHeader, TableRow, Text} from "grommet";
import {MarketInfo} from "../../types";
import styled from "styled-components";
import {Number, CoinName} from "../../components";
import {BigNumber} from "ethers";
import moment from "moment";
import {getIconByName} from "../../components";
import {getExplorerUrl} from "../../utils";
import {MarginUpdate} from "../../api/dataService";

const TableContainer = styled(Table)`
  thead {
    th {
      color: #858CAD;
      font-weight: 600;
      border: none;
    }
  }

  border-collapse: separate;
  border-spacing: 0;
  
  tbody {
    tr:nth-child(2n + 1) {
      background-color: #191D28;
      border-radius: 10px;
    }
  }

  //th:first-child, td:first-child {
  //  padding-left: 24px;
  //}

    td {
        white-space: nowrap; overflow-x: auto;
    }
`

export interface MarginEventsProps {
  markets: MarketInfo[]
  events: MarginUpdate[]
}

interface MarginEventRowProps {
  event: MarginUpdate;
  market?: MarketInfo;
}

const MarginEventRow = (props: MarginEventRowProps) => {
  const { market, event  } = props

  const eventName = +event.marginDelta > 0
    ? 'Deposit'
    : 'Withdrawal'
  const underlyingDecimals = market?.descriptor.underlyingDecimals || 6
  const underlyingName = market?.descriptor.underlyingName || 'N/A'
  const blockTimestampMoment = moment(+event.timestamp * 1000)
  const txUrl = `${getExplorerUrl()}/tx/${event.txHash}`

  return <TableRow key={event.txHash}>
      <TableCell width={'60px'}>{eventName}</TableCell>
      <TableCell>{event.market}</TableCell>
      <TableCell size={'1/3'}>
        <Box direction={'row'}>
          {BigNumber.from(event.marginDelta).gt(0) &&
            <Text>+</Text>
          }
          <Box direction={'row'} gap={'2px'}>
            <Number
              value={BigNumber.from(event.marginDelta)}
              decimals={underlyingDecimals}
              name={underlyingName}
            />
            <CoinName name={underlyingName} />
          </Box>
        </Box>
      </TableCell>
      <TableCell width={'60px'}>
        <Box>
          <Box>
            <Text>{blockTimestampMoment.format('h:mm a')}</Text>
          </Box>
          <Box>
            <Text style={{ opacity: 0.4 }}>
              {blockTimestampMoment.format('DD/MM/YYYY')}
            </Text>
          </Box>
        </Box>
      </TableCell>
      <TableCell width={'32px'}>
        <Anchor href={txUrl} target={'_blank'}>
          <Box>
            {getIconByName('external').icon}
          </Box>
        </Anchor>
      </TableCell>
    </TableRow>
}

export const MarginEvents = (props: MarginEventsProps) => {
  return <TableContainer>
    <TableHeader style={{ height: '35px' }}>
      <TableRow>
        <TableCell scope="col">
          <Box direction={'row'} align={'center'} gap={'2px'}>
            <Text size={'12px'} color={'textSecondary'}>EVENT</Text>
          </Box>
        </TableCell>
        <TableCell scope="col">
          <Box direction={'row'} align={'center'} gap={'2px'}>
            <Text size={'12px'} color={'textSecondary'}>MARKET</Text>
          </Box>
        </TableCell>
        <TableCell scope="col">
          <Box direction={'row'} align={'center'} gap={'2px'}>
            <Text size={'12px'} color={'textSecondary'}>MARGIN CHANGE</Text>
          </Box>
        </TableCell>
        <TableCell scope="col">
          <Box direction={'row'} align={'center'} gap={'2px'}>
            <Text size={'12px'} color={'textSecondary'}>DATE</Text>
          </Box>
        </TableCell>
        <TableCell scope="col">
          <Box direction={'row'} align={'center'} gap={'2px'}>
            <Text size={'12px'} color={'textSecondary'}>EXPLORER</Text>
          </Box>
        </TableCell>
      </TableRow>
    </TableHeader>
    <TableBody>
      {props.events.map((event) => {
        const market = props.markets.find(market => market.descriptor.id === event.marketId)
        return <MarginEventRow key={event.txHash} market={market} event={event} />
      })}
    </TableBody>
  </TableContainer>
}
