import {useAccount} from "wagmi";
import {Box, Text} from "grommet";
import {Menu as MenuIcon, Close as CloseIcon} from "grommet-icons";
import {getConfigMode} from "../../config";
import {NetworkTag} from "./NetworkTag";
import React, {useEffect} from "react";
import styled from "styled-components";
import {ReactComponent as RhoLogo} from '../../assets/images/rho_logo.svg';
import {useLocation, useNavigate} from "react-router-dom";
import {ConnectWallet} from "./ConnectWallet";

const HeaderMobileContainer = styled(Box)`
    z-index: 10;
    padding: 8px;
    position: relative;
`

const RhoTitle = styled(Text)`
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 18px;
  font-family: Rubik, sans-serif;
`

const MenuIconContainer = styled(Box)`
    padding: 8px;
    background: #26262C;
    border-radius: 4px;
    &:hover {
        background: #242833;
    }
`

const MobileMenuContainer = styled(Box)`
    z-index: 11;
    background: ${props => props.theme.global.colors.background};
    height: 100vh;
    width: 100vw;
`

const MobileMenuItem = (props: { text: string; route: string; onClick: (route: string) => void }) => {
  const { route } = props
  const { pathname } = useLocation()
  const activeRoute = pathname === '/' ? '/trade' : pathname
  const isActive = !!route && activeRoute.startsWith(`/${route}`)

  return <Box
    pad={'20px 24px'}
    onClick={() => props.onClick(route)}
  >
    <Text size={'18px'} color={isActive ? 'accentWhite' : 'text'}>
      {props.text}
    </Text>
  </Box>
}

const MobileMenu = (props: { onClick: (route: string) => void }) => {
  const { onClick } = props

  return <MobileMenuContainer>
    <MobileMenuItem text={'Trade'} route={'trade'} onClick={onClick} />
    <MobileMenuItem text={'Portfolio'} route={'portfolio'} onClick={onClick} />
    <MobileMenuItem text={'History'} route={'history'} onClick={onClick} />
    {/*<MobileMenuItem text={'Earn'} onClick={onClick} />*/}
  </MobileMenuContainer>
}

export const HeaderMobile = () => {
  const navigate = useNavigate();

  const [isMenuOpen, setMenuOpen] = React.useState<boolean>(false)

  const onMenuClicked = (route: string) => {
    navigate(route)
    setMenuOpen(false)
  }

  return <HeaderMobileContainer
    direction={'row'}
    justify={'between'}
    border={{ side: 'bottom', color: '#2D2E43' }}
  >
    <Box pad={{ top: '16px' }} style={{
      position: 'absolute',
      left: 0,
      top: 'calc(100% + 1px)',
      display: isMenuOpen ? 'block' : 'none',
    }}>
      <MobileMenu onClick={onMenuClicked} />
    </Box>
    <MenuIconContainer
      align={'center'} justify={'center'} onClick={() => setMenuOpen(!isMenuOpen)}
    >
      {isMenuOpen
        ? <CloseIcon size={'18px'} />
        : <MenuIcon size={'18px'} />
      }
    </MenuIconContainer>
    <Box direction={'row'} align={'center'} gap={'16px'}>
      <RhoLogo width={'18px'} height={'28px'} />
      <Box direction={'row'} gap={'8px'}>
        <Box direction={'row'} align={'center'} gap={'8px'}>
          <RhoTitle color={'accentWhite'}>Rho Protocol</RhoTitle>
          <Box>
            <Box pad={'2px 3px'} border={{ color: '#4852FF' }} round={'3px'}>
              <Text size={'10px'} weight={500} color={'#E4E4E8'}>BETA</Text>
            </Box>
          </Box>
        </Box>
        <NetworkTag />
      </Box>
    </Box>
    <Box>
      <ConnectWallet viewType={'mobile'} />
    </Box>
  </HeaderMobileContainer>
}
