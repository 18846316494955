import React from 'react'
import {Box} from "grommet";
import {PrimaryButton} from "../../../components";
import {useActiveModal} from "../../../providers/ModalsProvider";
import {PageModal} from "../../../constants";

export const ConnectWalletButton = () => {
  const { setActiveModal } = useActiveModal()

  return <Box>
    <PrimaryButton
      text={'Connect Wallet'}
      onClick={() => setActiveModal(PageModal.connectWallet)}
    />
  </Box>
}
