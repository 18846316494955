import {ConfigMode} from "./config";

export const NetworkNameMap: Record<ConfigMode, string>  = {
  'mainnet': 'Arbitrum One',
  'testnet': 'Arbitrum Sepolia',
}

export const NetworkTypeMap: Record<ConfigMode, string>  = {
  mainnet: 'Mainnet',
  testnet: 'Testnet',
}

export type ViewType = 'default' | 'ipad' | 'mobile'
export interface ViewTypeProps {
  viewType?: ViewType
}
