import React, {useEffect, useMemo, useState} from "react";
import {Select, Skeleton, Space} from "antd";
import {Box, Table, TableBody, TableCell, TableRow, Text} from "grommet";
import bn from "bignumber.js";
import styled from "styled-components";
import {TradeProps} from "../common";
import {FormErrors, RiskDirection, RiskDirectionType,} from "../../../types";
import tooltips from "../../../utils/tooltips";
import {
  Arrow,
  CoinName,
  DynamicValue,
  FormErrorMessage,
  GradientButton,
  InputWrapper,
  LeverageSlider,
  Number,
  QuestionMark,
  RiskDirectionSwitch,
  SwitchOption, TokenAmountInput,
  WidgetContainer
} from "../../../components";
import {ActionButtons} from "./ActionButtons";
import {BigNumber} from "ethers";
import {marginTotal, profitAndLossTotal} from "../../../utils/mappers";
import {bnToDecimal, numberToBN} from "../../../utils";
import {Tooltip} from "react-tooltip";
import {useInsufficientMargin} from "../../../hooks/useInsufficientMargin";
import {useProtocolData} from "../../../providers/ProtocolDataProvider";
import {ViewTypeProps} from "../../../app.types";
import {useActiveModal} from "../../../providers/ModalsProvider";

const TableContainer = styled(Table)`
  tr td:first-child {
    width: 40%;
  }
  tr td {
    padding: 8px 0;
  }
`

const TableText = (props: { children: any }) => {
  return <Text size={'13px'} color={'#7B7C8E'} weight={400}>{props.children}</Text>
}

export const TradeRequest = (props: TradeProps & ViewTypeProps) => {
  const {
    market,
    portfolio,
    futureId,
    future,
    underlyingDecimals,
    underlyingName,
    formValues,
    tradeQuote,
    formError,
    leverage,
    leverageRange,
    onChangeFormValue
  } = props

  const nativeTokenName = 'ETH' // chain?.nativeCurrency.symbol
  const {isProtocolUser, userPositions } = useProtocolData()
  const { activeModal } = useActiveModal()
  const [selectedTokenName, setSelectedTokenName] = useState(underlyingName)

  useEffect(() => {
    setSelectedTokenName(underlyingName)
  }, [underlyingName]);

  const onChangeRiskDirection = (option: SwitchOption) => {
    onChangeFormValue({
      riskDirection: option.id,
    })
  }

  useEffect(() => {
    if(tradeQuote && formValues.isMaxRateLimitAuto) {
      const tradeRate = formValues.riskDirection === RiskDirection.payer
        ? tradeQuote.payerQuote.tradeInfo.tradeRate
        : tradeQuote.receiverQuote.tradeInfo.tradeRate
      const plusValue = formValues.riskDirection === RiskDirection.payer ? '0.1' : '-0.1'
      const nextValue = new bn(tradeRate.toString())
        .div(Math.pow(10, 16))
        .plus(plusValue)
        .dp(4)
        .toString()
      onChangeFormValue({maxRateLimit: nextValue})
    }
  }, [formValues.isMaxRateLimitAuto, formValues.riskDirection, tradeQuote])

  const payerFixed = tradeQuote && !tradeQuote.insufficientLiquidityForPayer
    ? new bn(tradeQuote.payerQuote.tradeInfo.tradeRate.toString()).div(Math.pow(10, 16)).dp(2).toString()
    : '--'
  const receiverFixed = tradeQuote && !tradeQuote.insufficientLiquidityForReceiver
    ? new bn(tradeQuote.receiverQuote.tradeInfo.tradeRate.toString()).div(Math.pow(10, 16)).dp(2).toString()
    : '--'

  const onAdditionalCollateralClicked = () => {}

  const depositAddonAfter = <Box direction={'row'} align={'center'} gap={'10px'}>
    {!market!.descriptor.underlyingIsWrappedNativeToken &&
        <CoinName name={underlyingName} />
    }
    {market!.descriptor.underlyingIsWrappedNativeToken &&
        <Box width={'80px'}>
            <Select
                className={'antd_token_select'}
                size={'large'}
                defaultValue={underlyingName}
                suffixIcon={<Arrow direction={'down'} width={'10px'} />}
                dropdownStyle={{
                  backgroundColor: '#222530',
                  border: '1px solid #383D57',
                  borderRadius: '3px'
                }}
                onChange={(value: string) => {
                  setSelectedTokenName(value)
                  onChangeFormValue({isNativeTokenSelected: value === nativeTokenName})
                }}
            >
                <Select.Option value={underlyingName}>
                    <Space>
                        <Box>
                            <CoinName name={underlyingName} />
                        </Box>
                    </Space>
                </Select.Option>
                <Select.Option value={nativeTokenName}>
                    <Space>
                        <CoinName name={nativeTokenName} />
                    </Space>
                </Select.Option>
            </Select>
        </Box>
    }
  </Box>

  const onChangeSlider = (leverageValue: number) => {
    // console.log('onChangeSlider event leverage:', leverageValue)

    const totalMargin = portfolio ? marginTotal(portfolio.marginState.margin) : BigNumber.from(0)
    const openPosition = portfolio ? portfolio.futureOpenPositions.find(pos => pos.futureId === futureId) : undefined
    let openPositionNotional = openPosition ? openPosition.notional : BigNumber.from(0)
    if(openPosition && openPosition.riskDirection === RiskDirectionType.RECEIVER) {
      openPositionNotional = openPositionNotional.mul(-1)
    }
    const openPositionNotionalDecimal = bnToDecimal(openPositionNotional, underlyingDecimals)

    const newNotional = (
      bnToDecimal(totalMargin, underlyingDecimals)
      .mul(leverageValue)
      .sub(bnToDecimal(openPositionNotional, underlyingDecimals))
    )
      .abs()

    const decimals = underlyingName === "USDT" ? 2 : 6

    props.onChangeFormValue({
      'notional': newNotional.toFixed(decimals)
    })
    props.setLeverage(leverageValue)

    const currentLeverage = +openPositionNotionalDecimal.div(bnToDecimal(totalMargin, underlyingDecimals)).toNumber()

    let newRiskDirection = null
    if(formValues.riskDirection === RiskDirection.payer && leverageValue < currentLeverage) {
      newRiskDirection = RiskDirection.receiver
    } else if(formValues.riskDirection === RiskDirection.receiver && leverageValue > currentLeverage) {
      newRiskDirection = RiskDirection.payer
    }

    if(newRiskDirection) {
      props.onChangeFormValue({
        'riskDirection': newRiskDirection
      })
    }
  }

  const openPosition = portfolio ? portfolio.futureOpenPositions.find(pos => pos.futureId === futureId) : undefined
  const minLeverage = leverageRange.min
  const maxLeverage = leverageRange.max

  const profitAndLoss = useMemo(() => {
    if(futureId) {
      const position = userPositions.find(item => item.futureId === futureId)
      if(position) {
        return numberToBN(position.openPosition.position.profitAndLoss || 0, underlyingDecimals)
      }
    }
    return BigNumber.from(0)
  }, [userPositions, futureId])

  let openPositionNotional = openPosition ? openPosition.notional : BigNumber.from(0)
  if(openPosition && openPosition.riskDirection === RiskDirectionType.RECEIVER) {
    openPositionNotional = openPositionNotional.mul(-1)
  }
  const openPositionNotionalDecimal = bnToDecimal(openPositionNotional, underlyingDecimals)
  const totalMargin = portfolio ? marginTotal(portfolio.marginState.margin) : BigNumber.from(0)
  const currentLeverage = !openPositionNotionalDecimal.eq(0) ? +openPositionNotionalDecimal.div(bnToDecimal(totalMargin, underlyingDecimals)).toNumber() : undefined

  const isInsufficientMargin = useInsufficientMargin({ market, future, portfolio, tradeQuote, formValues, leverage, leverageRange })

  return <WidgetContainer justify={'between'}>
    <Box>
      <Box>
        <Box>
          <Text size={'20px'} weight={500} color={'textHeader'}>Trade</Text>
        </Box>
        <Box>
          <TableContainer>
            <TableBody>
              <TableRow>
                <TableCell>
                  <InputWrapper margin={{ top: '8px' }} style={{ border: '1px solid transparent' }}>
                    <Box direction={'row'} align={'center'} gap={'4px'}>
                      <TableText>Direction</TableText>
                      <QuestionMark tooltipId={'risk_direction'} tooltipText={tooltips.riskDirection} />
                    </Box>
                    <Box margin={{ top: '6px' }}>
                      <RiskDirectionSwitch
                        optionId={formValues.riskDirection}
                        options={[
                          {
                            id: RiskDirection.receiver,
                            description: `Receive fixed ${receiverFixed}%`
                          },
                          {
                            id: RiskDirection.payer,
                            description: `Pay fixed ${payerFixed}%`
                          }
                        ]}
                        onChange={onChangeRiskDirection}
                      />
                    </Box>
                  </InputWrapper>
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell>
                  <Box>
                    <TokenAmountInput
                      id={'notional'}
                      title={'Notional'}
                      description={tooltips.notional}
                      value={formValues.notional}
                      autoFocus={isProtocolUser && !activeModal}
                      status={formError && formError.field === 'notional' ? 'error' : ''}
                      options={[{ text: underlyingName }]}
                      onChange={(value) => onChangeFormValue({notional: value || '0'})}
                    />
                    {(formError && formError.field === 'notional') &&
                        <FormErrorMessage
                            formError={formError}
                            onClick={formError.type === FormErrors.collateralRequired
                              ? onAdditionalCollateralClicked
                              : undefined}
                        />
                    }
                  </Box>
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell>
                  <Box margin={{ top: '0px' }} style={{ minHeight: '80px' }}>
                    <LeverageSlider
                      futureId={futureId}
                      currentLeverage={currentLeverage}
                      step={0.5}
                      // disabled={!tradeQuote}
                      min={minLeverage}
                      max={maxLeverage}
                      value={leverage}
                      formRiskDirection={formValues.riskDirection}
                      onChange={onChangeSlider}
                    />
                    <Box margin={{ top: '10px', left: '4px' }}>
                      {isProtocolUser &&
                        <Box direction={'row'} justify={'between'}>
                          <Box direction={'row'} align={'center'} gap={'6px'}>
                            <Text color={isInsufficientMargin ? 'textWarning' : 'textSecondary'}>Current margin</Text>
                            <QuestionMark tooltipId={'trade_cur_margin'} tooltipText={tooltips.currentMargin} />
                          </Box>
                          <Number
                            value={portfolio ? marginTotal(portfolio.marginState.margin) : BigNumber.from(0)}
                            decimals={underlyingDecimals}
                            name={underlyingName}
                            showName={true}
                            fontColor={isInsufficientMargin ? 'textWarning' : 'accentWhite2'}
                          />
                        </Box>
                      }
                      {formError && formError.field === 'margin' &&
                          <FormErrorMessage
                              formError={formError}
                          />
                      }
                    </Box>
                  </Box>
                </TableCell>
              </TableRow>
            </TableBody>
          </TableContainer>
        </Box>
      </Box>
      <Box margin={{ top: props.viewType === 'mobile' ? '16px' : '0px' }}>
        <ActionButtons {...props} />
      </Box>
    </Box>
    <Box
      direction={'row'}
      margin={{ top: props.viewType === 'mobile' ? '16px' : '0px' }}
      border={{ size: '1px' }}
      round={'6px'}
      pad={'12px'}
      justify={'between'}
      align={'center'}
    >
      <Box gap={'4px'}>
        <Box direction={'row'} align={'center'} gap={'6px'}>
          <Text size={'13px'} color={'textSecondary'}>Current position P&L</Text>
          <QuestionMark tooltipId={'current_pos_pl'} tooltipText={tooltips.profitAndLoss} />
        </Box>
        <DynamicValue
          value={profitAndLoss}
          decimals={underlyingDecimals}
          name={underlyingName}
          showName={true}
          fontSize={'18px'}
        />
      </Box>
      <Box
        data-tooltip-id={'pl_calculator_tooltip'}
        data-tooltip-content={'Coming soon'}
      >
        <Tooltip
          id={'pl_calculator_tooltip'}
          place={'top-start'}
          border={'1px solid #454A55'}
          opacity={1}
          style={{
            fontSize: '14px',
            fontWeight: 'normal',
            textAlign: 'left',
          }}
        />
        <GradientButton text={'P&L Calculator'} />
      </Box>
    </Box>
  </WidgetContainer>
}
