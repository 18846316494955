import {PortfolioProps} from "../common";
import {Box} from "grommet";
import {ChartContainerHeight, LeftColumnWidth} from "../constants";
import {MarketSelect} from "../../../components/market-select";
import {MarketInfo} from "../../../types";
import {getMarketAlias} from "../../../utils";
import {WidgetsBox} from "../../../components";
import {BadgesPanel} from "../badges-panel";
import {PortfolioHistoryChart} from "../history-chart";
import {BottomPanel} from "../bottom-panel";
import React from "react";
import {useProtocolData} from "../../../providers/ProtocolDataProvider";
import {useFutureAlias} from "../../../hooks/useFutureAlias";
import {useNavigate} from "react-router-dom";

const gap = '16px'

export const PortfolioDesktop = (props: PortfolioProps) => {
  const {
    market: selectedMarket,
    isDropdownOpened,
    setDropdownOpened
  } = props

  const navigate = useNavigate();
  const { markets } = useProtocolData()
  const futureData = useFutureAlias(markets)

  return <Box margin={{ bottom: '16px' }} style={{ minWidth: '900px' }}>
    <Box
      margin={{ top: gap }}
      width={'calc(100vw - 32px)'}
      justify={'between'}
      direction={'row'}
      gap={gap}
    >
      <Box width={LeftColumnWidth} gap={'16px'}>
        <MarketSelect
          viewType={'default'}
          mode={'markets'}
          width={'547.5px'}
          market={selectedMarket}
          future={futureData?.future}
          onDropdownChange={setDropdownOpened}
          onSelect={(market: MarketInfo | 'all') => {
            navigate(`/portfolio/${market === 'all' ? 'all' : getMarketAlias(market)}`)
          }}
        />
        <WidgetsBox overlay={isDropdownOpened}>
          <BadgesPanel {...props} />
        </WidgetsBox>
      </Box>
      <WidgetsBox
        width={`calc(100% - ${LeftColumnWidth})`}
        height={ChartContainerHeight}
        overlay={isDropdownOpened}
      >
        <PortfolioHistoryChart {...props} />
      </WidgetsBox>
    </Box>
    <WidgetsBox
      margin={{ top: gap }}
      overlay={isDropdownOpened}
      style={{ minHeight: `calc(100vh - ${ChartContainerHeight} - 110px)` }}
    >
      <BottomPanel {...props} />
    </WidgetsBox>
  </Box>
}
