import React, {useEffect, useRef, useState} from 'react'
import {Box, Text} from "grommet";
import {TradeProps} from "../common";
import {
  Arrow, CompactSelect, GradientButton,
  MarketIcon,
  OpTypeBadge,
  QuestionMark,
  RateBadge,
  WidgetContainer
} from "../../../components";
import {FutureInfo, OpType} from "../../../types";
import {formatFloatRates, useRates} from "../../../providers/FloatRateProvider";
import tooltips from "../../../utils/tooltips";
import moment from "moment";
import useOnClickOutside from "../../../hooks/useOnClickOutside";
import {useNavigate} from "react-router-dom";
import {getFutureStats} from "../../../api/dataService";
import {fromBn} from "evm-bn";
import {ViewTypeProps} from "../../../app.types";
import useInterval from "../../../hooks/useInterval";
import {useAccount} from "wagmi";
import {useCurrentRate} from "../../../hooks/useCurrentRate";
import {MarketSelect} from "../../../components/market-select";
import {useProtocolData} from "../../../providers/ProtocolDataProvider";
import {getFutureAlias} from "../../../utils";
import {setLastUsedMarketAlias} from "../../../utils/localstorage";
import {useFutureAlias} from "../../../hooks/useFutureAlias";
import {configMode} from "../../../config";
import {ShareRounded} from "grommet-icons";

export const MarketTradeHeader = (props: TradeProps & ViewTypeProps) => {
  const {
    viewType = 'default',
    futureId,
    market,
    future,
    setModalId
  } = props

  const {
    currentRate: currentFutureRate,
    rateDelta
  } = useCurrentRate(futureId)

  const { futuresStats, markets } = useProtocolData()
  const futureData = useFutureAlias(markets)

  const rates = useRates()
  const navigate = useNavigate()
  const ref = useRef<any>();
  const { isConnected } = useAccount()

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [floatRateMode, setFloatRateMode] = useState<'APR' | '8h'>('APR')
  const [maturityCountdown, setMaturityCountdown] = useState('')

  useEffect(() => {
    setModalId(isModalOpen ? 'market' : '')
  }, [isModalOpen]);

  useEffect(() => {
    if(market) {
      if(!['funding', 'staking'].includes(market.descriptor.tag)) {
        setFloatRateMode('APR')
      }
    }
  }, [market]);

  const updateMaturityCountdown = (future: FutureInfo) => {
    const { termStart, termLength } = future

    const timestamp = termStart.add(termLength).mul(1000).toNumber()
    const delta = timestamp - Date.now()

    if(delta > 1000) {
      const duration = moment.duration(delta, 'milliseconds')
      const days = Math.floor(+duration.asDays().toString())
      const hours = duration.hours()
      const minutes = duration.minutes()
      const seconds = duration.seconds()
      setMaturityCountdown(`${days}d ${hours}h ${minutes}m ${seconds}s`);
    } else {
      setMaturityCountdown('Matured')
    }
  }

  useEffect(() => {
    if(future) {
      updateMaturityCountdown(future)
    }
  }, [future]);

  useInterval(() => {
    if(future) {
      updateMaturityCountdown(future)
    }
  }, 1000)

  useOnClickOutside(ref, () => setIsModalOpen(false));

  if(!market || !future) {
    return <Box>
      Loading
    </Box>
  }

  const { descriptor: {
    id: marketId,
    sourceName,
    underlyingName,
    instrumentName,
    underlyingDecimals,
    tag
  } } = market

  const floatRate = formatFloatRates({
    rates,
    sourceName,
    tag,
    marketId
  })

  let floatRateValue = 0
  let oracleEightHoursRate = null
  let futureEightHoursRate = null
  if(floatRate) {
    floatRateValue = tag === 'funding'
      ? floatRate.rate * 365 * 3
      : tag === 'staking'
        ?  floatRate.rate * 365
        : floatRate.rate

    if(tag === 'funding') {
      oracleEightHoursRate = floatRate.rate
      futureEightHoursRate = currentFutureRate.div(365 * 3)
    } else if (tag === 'staking') {
      oracleEightHoursRate = floatRate.rate
      futureEightHoursRate = currentFutureRate.div(365)
    }
  }

  const futureStats = futuresStats.find(item => item.futureId === futureId)
  const bpsChange = futureStats ? Math.round(futureStats.stats.rateChange / 10**16 * 100): 0

  const onMarketSelectClick = (e: React.MouseEvent<HTMLDivElement>) => {
    const selectElements = document.querySelectorAll('.rho_compact_select')
    // @ts-ignore
    if(selectElements.length > 0 && selectElements[0].contains(e.target)){

    } else {
      setIsModalOpen(!isModalOpen)
    }
  }

  if(viewType === 'mobile') {
    return <Box>
      <MarketSelect
        viewType={viewType}
        market={futureData?.market}
        future={futureData?.future}
        onDropdownChange={(isOpened) => props.setModalId(isOpened ? 'market' : '')}
        onSelect={(market, future) => {
          if(future && market && typeof market !== 'string') {
            const marketAlias = getFutureAlias(market, future)
            setLastUsedMarketAlias(marketAlias)
            navigate(`/trade/${marketAlias}?network=${configMode}`)
          }
        }}
      />
    </Box>
  }

  return <Box ref={ref} style={{ position: 'relative' }}>
    <WidgetContainer
      direction={'row'}
      style={{
        padding: '0 20px 0 0',
        // padding: '16px 20px 16px 0',
        borderBottomLeftRadius: isModalOpen ? '0px' : undefined
    }}
    >
      <Box
        direction={'row'}
        justify={'between'}
      >
        <Box
          direction={'row'}
          gap={'24px'}
        >
          <MarketSelect
            viewType={viewType}
            market={futureData?.market}
            future={futureData?.future}
            onDropdownChange={(isOpened) => props.setModalId(isOpened ? 'market' : '')}
            onSelect={(market, future) => {
              if(future && market && typeof market !== 'string') {
                const marketAlias = getFutureAlias(market, future)
                setLastUsedMarketAlias(marketAlias)
                navigate(`/trade/${marketAlias}?network=${configMode}`)
              }
            }}
          />
          <Box height={'calc(100% - 32px)'} alignSelf={'center'} border={{ side: 'left', size: '1px' }} />
          <Box justify={'center'}>
            <Box direction={'row'} width={'130px'} justify={'between'}>
              <Box style={{ display: floatRateMode === 'APR' ? 'flex' : 'none' }}>
                <RateBadge
                  id={futureId}
                  value={+fromBn(currentFutureRate, 16)}
                  color={rateDelta.eq(0) ? undefined : rateDelta.gt(0) ? 'ratePositive' : 'rateNegative'}
                  dynamicColorByDelta={false}
                  fractionalDigits={3}
                  size={'18px'}
                  weight={500}
                  suffix={'%'}
                />
              </Box>
              {futureEightHoursRate &&
                  <Box style={{ display: floatRateMode === '8h' ? 'flex' : 'none' }}>
                      <RateBadge
                          id={futureId}
                          value={+fromBn(futureEightHoursRate, 16)}
                          fractionalDigits={3}
                          size={'18px'}
                          weight={500}
                          suffix={'%'}
                      />
                  </Box>
              }
              {oracleEightHoursRate &&
                  <Box>
                      <CompactSelect
                          options={['APR', '8h']}
                          selectedOption={floatRateMode}
                          onSelect={(value) => setFloatRateMode(value as any)}
                      />
                  </Box>
              }
            </Box>
            <Box direction={'row'} align={'center'} gap={'4px'} margin={{ top: '2px' }}>
              <Text color={'textSecondary'}>Rho current rate</Text>
              <QuestionMark tooltipId={'future_rate'} tooltipText={tooltips.futureRate} />
            </Box>
          </Box>
          <Box height={'calc(100% - 32px)'} alignSelf={'center'} border={{ side: 'left', size: '1px' }} />
        </Box>
      </Box>
      <Box
        direction={'row'}
        pad={{ left: '24px' }}
        align={'center'}
        gap={'20px'}
      >
      </Box>
      <Box
        direction={'row'}
        // margin={{ left: '8px' }}
        pad={{ right: '8px' }}
        align={'center'}
        gap={'20px'}
      >
        <Box>
          <RateBadge
            id={futureId}
            value={bpsChange}
            size={'15px'}
            weight={400}
            suffix={' bps'}
            prefix={+bpsChange > 0 ? '+' : ''}
            dynamicColorByDelta={false}
            dynamicColorByAbsValue={true}
          />
          <Box direction={'row'} align={'center'} gap={'4px'} margin={{ top: '4px' }}>
            <Text color={'textSecondary'} size={'13px'}>24h change</Text>
            <QuestionMark tooltipId={'24_change'} tooltipText={'24h change'} />
          </Box>
        </Box>
        {viewType === 'default' &&
            <Box>
                <RateBadge
                    id={futureId}
                    value={((futureStats?.stats.low || 0) / 10 ** 16)}
                    fractionalDigits={2} size={'15px'}
                    weight={400}
                    suffix={'%'}
                    dynamicColorByDelta={false}
                />
                <Box direction={'row'} align={'center'} gap={'4px'} margin={{ top: '4px' }}>
                    <Text color={'textSecondary'} size={'13px'}>24h low</Text>
                    <QuestionMark tooltipId={'24h_low'} tooltipText='24h low' />
                </Box>
            </Box>
        }
        {viewType === 'default' &&
            <Box>
                <RateBadge
                    id={futureId}
                    value={((futureStats?.stats.high || 0) / 10 ** 16)}
                    fractionalDigits={2}
                    size={'15px'}
                    weight={400}
                    suffix={'%'}
                    dynamicColorByDelta={false}
                />
                <Box direction={'row'} align={'center'} gap={'4px'} margin={{ top: '4px' }}>
                    <Text color={'textSecondary'} size={'13px'}>24h high</Text>
                    <QuestionMark tooltipId={'24h_high'} tooltipText='24h high' />
                </Box>
            </Box>
        }
        {viewType === 'default' &&
            <Box width={'130px'}>
                <Box>
                    <Text size={'15px'} color={'accentWhite2'}>{maturityCountdown}</Text>
                </Box>
                <Box direction={'row'} align={'center'} gap={'4px'} margin={{ top: '4px' }}>
                    <Text color={'textSecondary'} size={'13px'}>Time to maturity</Text>
                    <QuestionMark tooltipId={'mat_countdown'} tooltipText='Time to maturity' />
                </Box>
            </Box>
        }
        {viewType === 'default' &&
            <Box justify={'center'}>
                <Box direction={'row'} gap={'6px'} align={'center'} onClick={() => {
                  const marketName = `${sourceName} ${instrumentName}`.toLowerCase()
                  let url = ''

                  if(marketName.includes('binance')) {
                    url = 'https://www.binance.com/en/futures/funding-history/perpetual/real-time-funding-rate'
                  } else if(marketName.includes('okx')) {
                    url = 'https://www.okx.com/trade-market/funding/swap'
                  } else if(marketName.includes('cesr')) {
                    url = 'https://www.coindesk.com/indices/ether/cesr'
                  }

                  if(url) {
                    window.open(url, '_blank')
                  }
                }}>
                    <MarketIcon name={sourceName} size={'20px'} iconSize={'13px'} borderSize={'1px'} />
                    <Box direction={'row'} justify={'between'}>
                          <Box style={{ display: floatRateMode === 'APR' ? 'flex' : 'none' }}>
                              <RateBadge
                                  id={futureId}
                                  value={floatRateValue}
                                  fractionalDigits={3}
                                  size={'15px'}
                                  weight={500}
                                  suffix={'%'}
                              />
                          </Box>
                        {oracleEightHoursRate &&
                            <Box style={{ display: floatRateMode === '8h' ? 'flex' : 'none' }}>
                                <RateBadge
                                    id={futureId}
                                    value={oracleEightHoursRate}
                                    fractionalDigits={3}
                                    size={'15px'}
                                    weight={500}
                                    suffix={'%'}
                                />
                            </Box>
                        }
                      </Box>
                      <ShareRounded size={'16px'} color={'accentWhite'} />
                </Box>
                <Box direction={'row'} align={'center'} gap={'4px'} margin={{ top: '4px' }}>
                    <Text color={'textSecondary'} size={'13px'}>Floating rate</Text>
                    <QuestionMark tooltipId={'float_rate'} tooltipText={tooltips.floatRate} />
                </Box>
            </Box>
        }
      </Box>
      {isConnected &&
          <Box margin={{ left: 'auto' }} align={'center'} justify={'center'}>
              <GradientButton onClick={() => {
                navigate(`/trade/liquidity/${getFutureAlias(market, future)}?network=${configMode}`)
              }} text={'Provide liquidity'} />
          </Box>
      }
    </WidgetContainer>
  </Box>
}
