import React from 'react'
import {Anchor, Box, Text} from "grommet";
import {NotificationBadge} from "../../../components";
import {ReactComponent as Link2Img} from '../../../assets/images/link2.svg';

export const RestrictedAccess = () => {
  return <NotificationBadge
    type={'warning'}
    title={'To trade on the mainnet, you must be whitelisted. Please join our Discord server and submit a request through the whitelisting ticket system.'}
    description={<Box>
      <Anchor href={'https://discord.gg/rhoprotocol'} target={'_blank'} >
          <Box direction={'row'} align={'center'} gap={'4px'}>
              <Text color={'textWarning'} weight={400} style={{ textDecoration: 'underline' }}>Request Access</Text>
              <Link2Img style={{ color: '#D6B65C', width: '14px', height: '14px' }} />
          </Box>
      </Anchor>

    </Box>}
  />
}
