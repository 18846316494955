import React, {useEffect, useState} from 'react'
import {Box, Spinner, Text} from "grommet";
import {HistoryFilters} from "./Filters";
import {useAccount} from "wagmi";
import {EventType, mapHistoryItem, TradeType} from "./common";
import {TradeEvents} from "./TradeEvents";
import {toast} from "react-toastify";
import {
  BaseHistoryParams, getMarginUpdates,
  getProvisions,
  getTradesInfo, HistoryItem,
  MarginUpdate,
  Provision,
  Trade,
} from "../../api/dataService";
import {useProtocolData} from "../../providers/ProtocolDataProvider";
import useInfiniteScroll from 'react-infinite-scroll-hook';
import {LiquidityEvents} from "./LiquidityEvents";
import {MarginEvents} from "./MarginEvents";
import {downloadCsv} from "../../utils/csvExport";
import {useMediaQuery} from "react-responsive";
import {breakpoints} from "../../utils/breakpoints";

const fetchItems = (type: EventType, params: BaseHistoryParams) => {
  if(type === 'trades') {
    return getTradesInfo(params)
  } else if(type === 'marginTransfers') {
    return getMarginUpdates(params)
  } else if(type === 'liquidityProvisions') {
    return getProvisions(params)
  }
  return []
}

const PageSize = 20
const PageLimit = 200

const filterUnique = (items: HistoryItem[]) => {
  return items.filter((value, index, self) =>
    self.findIndex(v => v.txHash === value.txHash) === index
  );
}

export const History = () => {
  const { isConnected, address: userAddress } = useAccount()
  const isMobile = useMediaQuery({ query: `(max-width: ${breakpoints.mobile})` })

  const { markets, portfolioInitialFetching } = useProtocolData()

  const [loadingError, setLoadingError] = useState<Error>()
  const [currentPage, setCurrentPage] = useState(1)
  const [hasNextPage, setHasNextPage] = useState<boolean>(false)
  const [isLoading, setLoading] = useState(false)
  const [isExportLoading ,setExportLoading] = useState(false)
  const [eventType, setFilterEventType] = useState<EventType>(EventType.tradeUpdate)
  const [filterMarkets, setFilterMarkets] = useState<string[]>([])
  const [filterTimestamp, setFilterTimestamp] = useState<number[]>([])
  const [filterFutureIds, setFilterFutureIds] = useState<string[]>([])
  const [trades, setTrades] = useState<Trade[]>([])
  const [provisions, setProvisions] = useState<Provision[]>([])
  const [marginUpdates, setMarginUpdates] = useState<MarginUpdate[]>([])
  const [tradeType, setTradeType] = useState(TradeType.all)
  const isDataLoading = isLoading || portfolioInitialFetching

  const pageItems = eventType === 'trades'
    ? trades
    : eventType === 'liquidityProvisions'
      ? provisions
      : marginUpdates

  const loadHistory = async (page?: number) => {
    console.log('loadHistory, page number:', page)
    if(page && page > PageLimit) {
      return
    }
    if(userAddress) {
      setLoading(true)
      try {
        const params: BaseHistoryParams = {
          userAddress,
          count: PageSize,
        }
        let moreDataToLoad = false
        if(eventType === 'trades') {
          if(page && trades[trades.length - 1]) {
            params.before = trades[trades.length - 1].timestamp
          }
          // if(tradeType !== 'all') {
          //   params.ownerIsMaker = tradeType === 'maker'
          // }
          const items = await getTradesInfo(params)
          // @ts-ignore
          setTrades((values) => filterUnique([...values, ...items]))
          moreDataToLoad = items.length === PageSize
        } else if(eventType === 'liquidityProvisions') {
          if(page && provisions[provisions.length - 1]) {
            params.before = provisions[provisions.length - 1].timestamp
          }
          const items = await getProvisions(params)
          // @ts-ignore
          setProvisions((values) => filterUnique([...values, ...items]))
          moreDataToLoad = items.length === PageSize
        } else if(eventType === 'marginTransfers') {
          if(page && marginUpdates[marginUpdates.length - 1]) {
            params.before = marginUpdates[marginUpdates.length - 1].timestamp
          }
          const items = await getMarginUpdates(params)
          // @ts-ignore
          setMarginUpdates((values) => filterUnique([...values, ...items]))
          moreDataToLoad = items.length === PageSize
        }
        if(page) {
          setCurrentPage(page)
        }
        setHasNextPage(moreDataToLoad)
      } catch (e) {
        console.error('Cannot load user events:', e)
        setHasNextPage(false)

        const message = ((e as Error).message || 'Unknown error, try again later').slice(0, 150)
        toast.error('Failed to load history: ' + message, {
          toastId: 'history_error'
        })
      } finally {
        setLoading(false)
      }
    }
  }

  const [sentryRef] = useInfiniteScroll({
    loading: isDataLoading,
    hasNextPage,
    onLoadMore: () => {
      if(!isDataLoading) {
        const nextPage = currentPage + 1
        loadHistory(nextPage)
      }
    },
    disabled: !isConnected || !!loadingError,
    rootMargin: '0px 0px 400px 0px',
  });

  const cleanup = () => {
    setLoading(false)
    setTrades([])
    setProvisions([])
    setMarginUpdates([])
    setLoadingError(undefined)
    setHasNextPage(false)
    setCurrentPage(1)
  }

  useEffect(() => {
    cleanup()
    loadHistory()
  }, [userAddress, tradeType]);

  useEffect(() => {
    if(!isConnected) {
      cleanup()
    }
    loadHistory()
  }, [isConnected, userAddress, eventType]);

  const onExportClicked = async () => {
    try {
      setExportLoading(true)
      let historyItems: HistoryItem[] = []
      for(let i = 0; i < PageLimit; i++) {
        const params: BaseHistoryParams = {
          userAddress,
          count: PageSize,
          before: historyItems.length > 0
            ? historyItems[historyItems.length - 1].timestamp
            : undefined,
          ownerIsMaker: tradeType !== 'all' ? tradeType === 'maker' : undefined,
        }
        const newItems = await fetchItems(eventType, params)
        historyItems.push(...newItems)
        if(newItems.length !== PageSize) {
          break
        }
      }
      const mappedItems = historyItems
        .map((item) => mapHistoryItem(item, markets))
      if(mappedItems.length > 0) {
        downloadCsv(mappedItems, `${eventType}_${userAddress}.csv`)
      } else {
        toast.success('History is empty')
      }
    } catch(e) {
      setLoadingError(e as Error)
      console.error('Failed to load history', e)
      toast.error('Failed to export history')
    } finally {
      setExportLoading(false)
    }
  }

  const viewType = isMobile ? 'mobile' : 'default'

  return <Box
    margin={{ top: '32px', bottom: '32px' }}
    style={{ width: 'calc(100vw - 32px)' }}
  >
    <HistoryFilters
      viewType={viewType}
      isLoading={isDataLoading}
      tradeType={tradeType}
      setTradeType={setTradeType}
      isExportLoading={isExportLoading}
      markets={markets}
      filterEventType={eventType}
      setFilterEventType={(type) => {
        setTrades([])
        setProvisions([])
        setMarginUpdates([])
        setCurrentPage(1)
        setFilterEventType(type)
      }}
      filterMarkets={filterMarkets}
      setFilterMarkets={setFilterMarkets}
      filterTimestamp={filterTimestamp}
      setFilterTimestamp={setFilterTimestamp}
      filterMaturity={filterFutureIds}
      setFilterMaturity={setFilterFutureIds}
      onExportClicked={onExportClicked}
    />
    <Box
      align={'center'}
      width={'100%'}
      margin={{ top: '24px' }}
      pad={'24px 32px'}
      border={{ color: '#32353F' }}
      round={'3px'}
      style={{ minHeight: '140px', position: 'relative', overflowX: 'scroll' }}
    >
      {/*{isDataLoading &&*/}
      {/*    <Box justify={'center'} align={'center'} height={'100%'} style={{ position: 'absolute' }}>*/}
      {/*        <Spinner color={'spinner'} />*/}
      {/*    </Box>*/}
      {/*}*/}
      <Box
        width={'100%'}
        height={'100%'}
        // style={{ opacity: isDataLoading ? 0.4 : 1 }}
      >
        {!portfolioInitialFetching && eventType === EventType.tradeUpdate &&
            <TradeEvents
                userAddress={userAddress}
                markets={markets}
                events={trades}
            />
        }
        {!portfolioInitialFetching && eventType === EventType.liquidityUpdate &&
            <LiquidityEvents
                walletAddress={userAddress}
                markets={markets}
                events={provisions}
            />
        }
        {!portfolioInitialFetching && eventType === EventType.marginUpdate &&
            <MarginEvents
                markets={markets}
                events={marginUpdates}
            />
        }
        {isConnected && !isDataLoading && (pageItems.length === 0) &&
            <Box margin={{ top: '16px' }} width={'100%'} height={'100%'} justify={'center'} align={'center'}>
                <Text color={'textSecondary'} size={'large'}>Events not found</Text>
            </Box>
        }
        {!isConnected && !isDataLoading && trades.length === 0 &&
            <Box margin={{ top: '16px' }} width={'100%'} height={'100%'} justify={'center'} align={'center'}>
                <Text color={'textSecondary'} size={'large'}>Connect your account to see history events</Text>
            </Box>
        }
        {(isDataLoading || hasNextPage) &&
          <Box margin={{ top: '32px' }} width={'100%'} justify={'center'} align={'center'} ref={sentryRef}>
              <Spinner color={'spinner'} />
          </Box>
        }
      </Box>
    </Box>
  </Box>
}
