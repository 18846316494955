import React, {useMemo} from 'react'
import {Box, Table, TableBody, TableCell, TableHeader, TableRow, Text} from "grommet";
import {FutureInfo, IRateMathType, MarketInfo, RiskDirectionType} from "../../types";
import {MarketIcon, Number} from "../";
import {useProtocolData} from "../../providers/ProtocolDataProvider";
import {marginTotal} from "../../utils/mappers";
import {BigNumber} from "ethers";
import {fromBn} from "evm-bn";
import moment from "moment/moment";
import bn from "bignumber.js";
import styled from "styled-components";
import {bnToDecimal, formatNumber, getShortDate} from "../../utils";
import {Decimal} from "decimal.js";
import {CompoundingRateMath, LinearRateMath} from "../../utils/leverage";
import {MarketSelectProps, SelectMarket} from "./index";
import {useFutureAlias} from "../../hooks/useFutureAlias";

const zero = BigNumber.from(0)

const TableContainer = styled(Table)`
  border-collapse: separate;
  border-spacing: 0 6px;
    
  thead {
    th {
      border: none;
      padding: 4px 8px;
    }
  }
  
  tbody {
    tr {
      cursor: pointer;
      transition: background-color 0.2s;

      td {
        padding: 3px 8px;
      }
      
      td:first-child {
        width: 200px;
        font-weight: 400;
        border-top-left-radius: 4px;
        border-bottom-left-radius: 4px;
      }
      
      td:last-child {
        border-top-right-radius: 4px;
        border-bottom-right-radius: 4px;
      }
    }
    
    tr:hover {
      background: #2E2E38;
    }
    
    //tr:nth-child(2n + 1):not(:hover) {
    //  background: #242429;
    //}
  }

  //td:last-child {
  //  text-align: right;
  //}
`

const MarketTable = (props: MarketSelectProps & {
  market: MarketInfo
}) => {
  const { market, searchValue = '' } = props

  const { futuresStats, markets } = useProtocolData()
  const currentFuture = useFutureAlias(markets)

  const { riskParameters } = market

  const rateMath = market.descriptor.rateMathType === IRateMathType.LINEAR
    ? new LinearRateMath()
    : new CompoundingRateMath()

  return <Box width={'100%'}>
    <TableContainer>
      <TableHeader>
        <TableRow>
          <TableCell scope="col">
            <Text size={'13px'} color={'textSecondary'}>MATURITY</Text>
          </TableCell>
          <TableCell scope="col">
            <Text size={'13px'} color={'textSecondary'}>RATE</Text>
          </TableCell>
          <TableCell scope="col">
            <Text size={'13px'} color={'textSecondary'}>{props.viewType === 'default' ? '24H CHANGE' : '24H'}</Text>
          </TableCell>
        </TableRow>
      </TableHeader>
      <TableBody>
        {market.futures.map(future => {
          const { id: futureId } = future
          const futureRate = fromBn(future.vAMMParams.currentFutureRate, 16)
          const statsData = futuresStats.find(item => item.futureId === futureId)
          const bpsChange = statsData ? Math.round(statsData.stats.rateChange / 10**16 * 100): null

          const currentRate = future
            ? new Decimal(bnToDecimal(future.vAMMParams.currentFutureRate, 18))
            : new Decimal(0)
          const delta = new Decimal(bnToDecimal(riskParameters.marginThresholdDelta, 18))
          const marginRequirementSecondsFloor = riskParameters.marginRequirementSecondsFloor

          const secondsToMaturity = future
            ? future.termStart.add(future.termLength).toNumber() - Math.round(Date.now() / 1000)
            : 0

          const leverage = rateMath.calcFutureMaxLeverage(
            currentRate,
            delta,
            secondsToMaturity,
            marginRequirementSecondsFloor,
            RiskDirectionType.PAYER
          )

          const isSelected = futureId === currentFuture?.future.id

          const style: any= {}
          if(isSelected) {
            style.background = '#2E2E38'
          }

          let bpsChangeColor = 'colorTextBase'
          let bpsChangeText = 'N/A'
          if (bpsChange !== null) {
            bpsChangeText = `${bpsChange} bps`
            if (bpsChange > 0) {
              bpsChangeColor = 'ratePositive'
            } else if (bpsChange < 0) {
              bpsChangeColor = 'rateNegative'
            }
          }

          return <TableRow
            key={futureId}
            style={style}
            onClick={() => props.onSelect(market, future)}
          >
            <TableCell size={props.viewType === 'default' ? '130px' : '100px'}>
              <Box direction={'row'} gap={'10px'} align={'center'}>
                <Text>
                  {getShortDate(future.termStart.add(future.termLength).toNumber() * 1000)}
                </Text>
                <Box background={isSelected ? '#454554' : '#393946'} height={'17px'} align={'center'} round={'3px'} justify={'center'} pad={'0px 8px'}>
                  <Text color={'#D3D3DE'} size={'11px'} weight={500}>{leverage}x</Text>
                </Box>
              </Box>
            </TableCell>
            <TableCell size={'100px'}>
              <Text>{new bn(futureRate).dp(4).toString()} %</Text>
            </TableCell>
            <TableCell size={'100px'}>
              <Text color={bpsChangeColor}>{bpsChangeText}</Text>
            </TableCell>
          </TableRow>
        })}
      </TableBody>
    </TableContainer>
  </Box>
}

const MarketItem = (props: MarketSelectProps & {
  market: SelectMarket
}) => {
  const { market, mode = 'default' } = props

  const { portfolio, accountTotalMarginUSD } = useProtocolData()

  const {
    sourceName,
    instrumentName,
    underlyingName,
    underlyingDecimals,
    margin
  } = useMemo(() => {
    if(market && market !== 'all') {
      const { descriptor: {
        id,
        sourceName,
        instrumentName,
        underlyingName,
        underlyingDecimals
      } } = market

      const marketPortfolio = portfolio.find(item => item.descriptor.id === id)
      const margin = marketPortfolio ? marginTotal(marketPortfolio.marginState.margin) : zero

      return {
        id,
        sourceName,
        instrumentName,
        underlyingName,
        underlyingDecimals,
        margin
      }
    }

    return {
      id: 'all',
      sourceName: 'All markets',
      instrumentName: '',
      underlyingName: portfolio.length > 0 ? portfolio[0].descriptor.underlyingName : '',
      underlyingDecimals: portfolio.length > 0 ? portfolio[0].descriptor.underlyingDecimals : 6,
      margin: zero
    }
  }, [market])

  const rowHeight = 34

  return <Box>
    <Box direction={'row'} align={'center'} justify={'between'}>
      <Box direction={'row'} align={'center'}>
        <Box width={'32px'}>
          <MarketIcon
            name={market === 'all' ? 'layer' : sourceName}
            tokenName={market === 'all' ? '' : underlyingName}
            size={'32px'}
            iconSize={'18px'}
            minorIconSize={'14px'}
          />
        </Box>
        <Box margin={{ left: '12px' }}>
          <Text
            size={props.viewType === 'mobile' ? '14px' : '16px'}
            color={'accentWhite'}
          >
            {sourceName} {instrumentName}
          </Text>
        </Box>
      </Box>
      <Box
        direction={'row'}
        gap={'6px'}
        align={'center'}
        style={{
          position: 'absolute',
          left: props.viewType === 'default'
            ? '322px'
            : '290px'
      }}
      >
        {props.viewType === 'default' && (<Text size={'13px'} color={'textSecondary'}>Margin:</Text>)}
        {market === 'all'
          ?
            <Text
              size={props.viewType === 'default' ? '14px' : '12px'}
              weight={props.viewType === 'default' ? 500 : 400}
            >${formatNumber(accountTotalMarginUSD)}</Text>
          : <Number
            value={margin}
            decimals={underlyingDecimals}
            name={underlyingName}
            showName={true}
            fontSize={props.viewType === 'default' ? '14px' : '12px'}
            fontColor={'accentWhite2'}
            fontWeight={props.viewType === 'default' ? 500 : 400}
          />
        }
      </Box>
    </Box>
    {(mode === 'default' && market && market !== 'all') &&
        <Box direction={'row'}>
            <Box
                width={'32px'}
                align={'center'}
                style={{ position: 'relative' }}
            >
                <Box
                    width={'1px'}
                    margin={{ top: '8px' }}
                    height={'calc(100% - 26px)'}
                    background={'#818298'}
                />
                {market.futures.map((future, index) => {
                  return <Box
                    key={future.id}
                    id={future.id}
                    height={'1px'}
                    width={'6px'}
                    background={'#818298'}
                    style={{
                      position: 'absolute',
                      left: '16px',
                      top: `${(rowHeight / 2 + 6) + (index + 1) * rowHeight}px`
                    }}
                  />
                })}
            </Box>
            <Box margin={{ left: '12px' }} width={'calc(100% - 32px)'}>
                <MarketTable key={market.descriptor.id} {...props} market={market} />
            </Box>
        </Box>
    }
  </Box>
}

const MarketItemContainer = styled(Box)`
  &:hover {
  }
`

export const MarketsTable = (props: MarketSelectProps & {
  markets: MarketInfo[]
}) => {
  const { mode = 'default' } = props

  let markets: SelectMarket[] = [...props.markets]
  if(mode === 'markets') {
    markets = ['all', ...markets]
  }

  return <Box>
    {markets.map((market, index, arr) => {
      const onClick = props.mode === 'markets' ? () => {
        props.onSelect(market)
      } : undefined
      const isLastElement = index === arr.length - 1

      return <MarketItemContainer
          key={market === 'all' ? 'all' : market?.descriptor.id}
          pad={{
           top: '12px',
           bottom: '12px'
          }}
          border={{ size: !isLastElement ? '1px' : '0px', side: 'bottom' }}
          onClick={onClick}
      >
        <MarketItem {...props} market={market} />
      </MarketItemContainer>
      }
    )}
  </Box>
}
