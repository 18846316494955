import styled from "styled-components";
import {Box} from "grommet";

export const MenuIconContainer = styled(Box)`
    padding: 8px;
    margin-left: 8px;
    background: #26262C;
    border-radius: 4px;
    &:hover {
        background: #242833;
    }
`
