import React, {createContext, useContext, useState, PropsWithChildren} from 'react';
import {PageModal} from "../constants";

interface ModalParams {
  marketId?: string
  type?: 'deposit' | 'withdraw'
}

export interface ModalsState {
  activeModal: PageModal | null
  modalParams?: ModalParams
  setActiveModal: (newModal: PageModal | null, params?: ModalParams) => void
  closeModals: () => void
}

const getInitialState = (): ModalsState => {
  return {
    activeModal: null,
    modalParams: undefined,
    setActiveModal: () => {},
    closeModals: () => {}
  }
}

const initialState = getInitialState()
const UserDataContext = createContext(initialState);

export const useActiveModal = () => useContext(UserDataContext);

export const ModalsProvider: React.FC<PropsWithChildren<unknown>> = ({ children }) => {
  const [activeModal, setActiveModal] = useState<PageModal | null>(null)
  const [modalParams, setModalParams] = useState<ModalParams>()

  const closeModals = () => {
    setActiveModal(null)
    setModalParams(undefined)
  }

  return <UserDataContext.Provider value={{
    activeModal,
    modalParams,
    setActiveModal: (newModal: PageModal | null, params?: ModalParams) => {
      setActiveModal(newModal)
      setModalParams(params)
    },
    closeModals
  }}>
    {children}
  </UserDataContext.Provider>
};
