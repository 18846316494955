import React from "react";
import {useTranslation} from "react-i18next";
import { useWeb3Modal, useWeb3ModalState } from "@web3modal/wagmi/react";
import {useConnect, useDisconnect} from "wagmi";
import {connect} from "wagmi/actions";
import {ReactComponent as Link2Img} from '../assets/images/link2.svg';
import {WidgetContainer} from "../components";
import {Anchor, Box, Text} from "grommet";
import {useActiveModal} from "../providers/ModalsProvider";
import styled from "styled-components";
import {ReactComponent as MetamaskLogo} from '../assets/images/metamask_logo.svg';
import {ReactComponent as WalletConnectLogo} from '../assets/images/wallet_connect_logo.svg';

const CloseIcon = styled(Box)`
    transition: transform 250ms;
    &:hover {
        transform: scale(1.2);
    }
`

interface OptionProps {
  disabled?: boolean
  text: string
  icon: any
  description: string
  onClick: () => void
}

const ConnectOptionContainer = styled(Box)`
  background: #26262c;
  transition: background-color 250ms;
  &:hover {
    background: #2f3037;
  }
`

const ConnectWalletOption = (props: OptionProps) => {
  const { disabled } = props
  return <ConnectOptionContainer
    pad={'16px'}
    gap={'16px'}
    direction={'row'}
    round={'8px'}
    onClick={!disabled ? props.onClick : undefined}
    style={{ opacity: disabled ? 0.4 : 'unset' }}
  >
    <Box width={'38px'} height={'38px'}>
      {props.icon}
    </Box>
    <Box justify={'between'}>
      <Box>
        <Text size={'16px'} weight={500}>{props.text}</Text>
      </Box>
      <Box>
        <Text weight={400} color={'textSecondary'}>{props.description}</Text>
      </Box>
    </Box>
  </ConnectOptionContainer>
}

export const ConnectWalletModal = () => {
  const { t } = useTranslation()
  const {
    open: openWeb3Modal,
  } = useWeb3Modal()
  const { open: isWeb3ModalOpen } = useWeb3ModalState()
  const { connectors } = useConnect()
  const { disconnectAsync } = useDisconnect()

  const { closeModals } = useActiveModal()

  const metamaskConnector = connectors
    .find((item) => item.id.toLowerCase() === 'metamask')

  const onClickMetamask = async () => {
    if(metamaskConnector) {
      try {
        await disconnectAsync()
        const res = await connect({
          connector: metamaskConnector
        })
        console.log('Wallet connected:', res)
        closeModals()
      } catch (e) {
        console.error('Metamask: failed to connect:', e)
      }
    }
  }

  const onClickWalletConnect = async () => {
    try {
      await openWeb3Modal()
      closeModals()
    } catch (e) {
      console.error('Wallet connect error:', e)
    }
  }

  const onClickOutside = async () => {
    if(isWeb3ModalOpen) {
      // await closeWeb3Modal()
    }
    closeModals()
  }

  return <WidgetContainer border={{ size: '1px' }}>
    <Box
      direction={'row'}
      justify={'between'}
    >
      <Text size={'18px'}>Connect Wallet</Text>
      <CloseIcon onClick={() => closeModals()}>
        <Text size={'20px'}>✕</Text>
      </CloseIcon>
    </Box>
    <Box margin={{ top: '16px' }}>
      <Text size={'14px'} style={{ lineHeight: '20px' }}>
        By connecting a wallet, you agree to Rho Protocol <Anchor href={'https://www.rho.trading/terms'} color={'brandRho'} target={'_blank'}>Terms of Service</Anchor> and represent and warrant to Rho Protocol that you are not a <Anchor href={'/restricted-persons'} target={'_blank'} color={'brandRho'}>Restricted Person</Anchor>.
      </Text>
    </Box>
    <Box margin={{ top: '32px' }} gap={'16px'} pad={{ bottom: '16px' }}>
      <ConnectWalletOption
        disabled={!window.ethereum}
        text={window.ethereum ? t('wallet.metamask_title') : 'Browser extension'}
        icon={window.ethereum ? <MetamaskLogo style={{ width: 'inherit' }} /> : null}
        description={window.ethereum ? t('wallet.metamask_description') : 'Not Available'}
        onClick={onClickMetamask}
      />
      <ConnectWalletOption
        text={'WalletConnect'}
        icon={<WalletConnectLogo style={{ width: 'inherit' }} />}
        description={t('wallet.wc_description')}
        onClick={onClickWalletConnect}
      />
    </Box>
    <Box margin={{ top: '16px' }}>
      <Text>Don't have a wallet?</Text>
      <Anchor href={'https://ethereum.org/en/wallets/find-wallet/'} target={'_blank'} >
        <Box direction={'row'} align={'center'} gap={'4px'}>
          <Text color={'white'} weight={400} style={{ textDecoration: 'underline' }}>Create One</Text>
          <Link2Img style={{ color: 'white', width: '14px', height: '14px' }} />
        </Box>
      </Anchor>
    </Box>
  </WidgetContainer>
}
